import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Camera from '@mui/icons-material/Camera';

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  borderBottom: '1px solid #e0e0e0',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function DashboardHeader(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open, setOpen } = props;

  const [helpMenuEl, setHelpMenuEl] = useState(null);
  const [appsMenuEl, setAppsMenuEl] = useState(null);
  const helpMenuOpen = Boolean(helpMenuEl);
  const appsMenuOpen = Boolean(appsMenuEl);

  function handleOpenHelpMenu(event) {
    setHelpMenuEl(event.currentTarget);
  }

  const handleCloseHelpMenu = () => {
    setHelpMenuEl(null);
  };

  function handleOpenAppsMenu(event) {
    setAppsMenuEl(event.currentTarget);
  }

  const handleCloseAppsMenu = () => {
    setAppsMenuEl(null);
  };

  return (
    <AppBar position='fixed' open={open}>
      <Toolbar style={{ justifyContent: 'space-between', paddingLeft: 12 }}>
        <Tooltip title={open ? 'Fermer menu' : 'Ouvrir menu'}>
          <IconButton onClick={() => setOpen(!open)}>{open ? <ChevronLeftIcon /> : <MenuIcon />}</IconButton>
        </Tooltip>
        <Grid style={{ justifyContent: 'right' }}>
          <Button
            onClick={() => {
              navigate('/dashboard/projects?new=true');
            }}
            className='btn-primary'
            style={{ marginRight: 15 }}
            sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
          >
            {t('create_project')}
          </Button>
          <Tooltip title="Besoin d'aide ?">
            <IconButton onClick={handleOpenHelpMenu}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id='help-menu'
            anchorEl={helpMenuEl}
            open={helpMenuOpen}
            onClose={handleCloseHelpMenu}
            MenuListProps={{
              'aria-labelledby': 'help-menu-button',
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                navigate('/support');
              }}
            >
              {t('support')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/faq');
              }}
            >
              {t('faq_short')}
            </MenuItem>
          </Menu>
          <Tooltip title='Nos autres solutions'>
            <IconButton onClick={handleOpenAppsMenu}>
              <AppsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id='apps-menu'
            anchorEl={appsMenuEl}
            open={appsMenuOpen}
            onClose={handleCloseAppsMenu}
            MenuListProps={{
              'aria-labelledby': 'apps-menu-button',
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                window.open('https://studio.mymoojo.com', '_blank');
              }}
            >
              <ListItemIcon>
                <Camera fontSize='small' />
              </ListItemIcon>
              <ListItemText>{t('apps_studio')}</ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default DashboardHeader;
