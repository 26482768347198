import React, { useContext } from 'react';
import DashboardSidebar from './Dashboard/DashboardSidebar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserContext } from '../uses/users.context';
import DashboardHeader from './Dashboard/DashboardHeader';
import Box from '@mui/material/Box';
import { themeContext } from '../uses/theme.uses';
import '../css/_dashboard.scss';

function ProtectedLayout() {
  const [open, setOpen] = React.useState(true);
  const { user } = useContext(UserContext);
  const { theme } = useContext(themeContext);
  const location = useLocation();

  if (!user) {
    // Redirect to the login page if the user is not logged in
    return <Navigate to={'/login'} state={{ prevPath: location.pathname }} />;
  }

  return (
    <div className='dashboard'>
      <DashboardSidebar open={open} setOpen={setOpen} />

      <div>
        <DashboardHeader open={open} setOpen={setOpen} />

        <Box
          style={{
            paddingTop: 64,
            marginLeft: 64,
            ...(open && {
              marginLeft: 250,
              width: `calc(100% - 250px)`,
              transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Outlet />
        </Box>
      </div>
    </div>
  );
}

export default ProtectedLayout;
