import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import { themeContext } from '../../uses/theme.uses';
import Avatar from '@mui/material/Avatar';
import { UserContext } from '../../uses/users.context';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#f8f8f8',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#f8f8f8',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function DashboardSidebar(props) {
  const { theme } = useContext(themeContext);
  const { open } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logoutUser } = useContext(UserContext);
  const anchorOpen = Boolean(anchorEl);

  function handleOpenUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    logoutUser().then(() => {
      navigate('/');
    });
  };

  const menuItems = [
    [t('menu_getting_started'), <LightbulbIcon key={1} />, '/dashboard'],
    [t('menu_my_contributions'), <VolunteerActivismIcon key={2} />, '/dashboard/contributions'],
    [t('menu_my_projects'), <LibraryBooksIcon key={3} />, '/dashboard/projects'],
    [t('my_account'), <ManageAccountsIcon key={4} />, '/dashboard/account'],
  ];

  // Move contributions to the second position if the user doesn't come from a contribution
  const contributionsIndex = menuItems.findIndex((item) => item[0] === t('menu_my_contributions'));
  if (user.from !== 'from_contribution' && contributionsIndex !== -1) {
    const contributionsItem = menuItems.splice(contributionsIndex, 1)[0];
    menuItems.splice(1, 0, contributionsItem);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <Box sx={{ margin: 'auto', pt: 2, display: open ? 'block' : 'none' }}>
            <img
              src={theme.images.logo}
              width='auto'
              height='50px'
              alt='MyMoojo'
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer', verticalAlign: 'middle' }}
            />
          </Box>
        </DrawerHeader>
        <List style={{ flex: 1 }}>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                style={{
                  borderLeft:
                    location.pathname === item[2] ? `5px solid ${theme.palette.secondary.main}` : `5px solid #f8f8f8`,
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 3,
                  py: 1.2,
                }}
                onClick={() => navigate(item[2])}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  style={{
                    color: location.pathname === item[2] ? theme.palette.secondary.main : '',
                  }}
                >
                  {item[1]}
                </ListItemIcon>
                <ListItemText
                  primary={item[0]}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{
                    color: location.pathname === item[2] ? theme.palette.secondary.main : '',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List>
          {[[t('my_profile'), <AccountBoxIcon key={1} />, `/user/${user.id}`]].map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                style={{
                  borderLeft: location.pathname === item[2] ? '5px solid' : '',
                  borderColor: location.pathname === item[2] ? theme.palette.secondary.main : '',
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4,
                  py: 1.2,
                }}
                onClick={() => navigate(item[2])}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                  style={{
                    color: location.pathname === item[2] ? theme.palette.secondary.main : '',
                  }}
                >
                  {item[1]}
                </ListItemIcon>
                <ListItemText
                  primary={item[0]}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{
                    color: location.pathname === item[2] ? theme.palette.secondary.main : '',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id='basic-button'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={handleOpenUserMenu}
              aria-controls='basic-menu'
              aria-haspopup='true'
              aria-expanded={anchorOpen ? 'true' : undefined}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Avatar alt='' src={user?.picture}>
                  {user && user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={user.firstName + ' ' + user.lastName} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
            <Menu
              id='avatar-menu'
              anchorEl={anchorEl}
              open={anchorOpen}
              onClose={handleCloseUserMenu}
              MenuListProps={{
                'aria-labelledby': 'avatar-menu-button',
              }}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      bottom: 15,
                      left: -3,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'left', vertical: 'center' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {user.role?.rank === 2 && (
                <div>
                  <MenuItem
                    onClick={() => {
                      navigate('/admin');
                    }}
                  >
                    {t('administration')}
                  </MenuItem>
                  <Divider />
                </div>
              )}
              <MenuItem onClick={logout}>{t('log_out')}</MenuItem>
            </Menu>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

export default DashboardSidebar;
