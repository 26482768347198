import React, { useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { ThemeDataProvider } from '../routes/ThemeDataProvider';
import SentryRoutes from '../utils/sentry';
import ProtectedLayout from './ProtectedLayout';
import { ProtectedRoute } from '../routes/ProtectedRoute';
import { BrandChecker } from '../routes/BrandChecker';
import SegmentService from '../services/segment.service';
import packageJson from '../../package.json';

import '../css/_index.scss';
import '../i18n';
import { Loading } from './Loading';

// Lazy load components
const Legal = lazy(() => import('./Legal'));
const Faq = lazy(() => import('./Faq'));
const Support = lazy(() => import('./Support'));
const ListOfProjects = lazy(() => import('../routes').then((module) => ({ default: module.ListOfProjects })));
const LoginIndex = lazy(() => import('../routes').then((module) => ({ default: module.LoginIndex })));
const SignupIndex = lazy(() => import('../routes').then((module) => ({ default: module.SignupIndex })));
const PasswordResetIndex = lazy(() => import('../routes').then((module) => ({ default: module.PasswordResetIndex })));
const ProjectsIndex = lazy(() => import('../routes').then((module) => ({ default: module.ProjectsIndex })));

// Admin routes lazy loading
const AdminDashboardIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminDashboardIndex })));
const AdminProjectsIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminProjectsIndex })));
const AdminUsersIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminUsersIndex })));
const AdminTasksIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminTasksIndex })));
const AdminSDGsIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminSDGsIndex })));
const AdminPartnershipsIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminPartnershipsIndex })),
);
const AdminCategoriesIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminCategoriesIndex })),
);
const AdminParametersIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminParametersIndex })),
);
const AdminBrandsIndex = lazy(() => import('../routes').then((module) => ({ default: module.AdminBrandsIndex })));
const AdminPresentationFieldsIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminPresentationFieldsIndex })),
);
const AdminFeaturedItemsIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminFeaturedItemsIndex })),
);
const AdminFeaturedUsersIndex = lazy(() =>
  import('../routes').then((module) => ({ default: module.AdminFeaturedUsersIndex })),
);

// User dashboard routes lazy loading
const DashboardIndex = lazy(() => import('../routes').then((module) => ({ default: module.DashboardIndex })));
const UserAccountIndex = lazy(() => import('../routes').then((module) => ({ default: module.UserAccountIndex })));
const ContributionsIndex = lazy(() => import('../routes').then((module) => ({ default: module.ContributionsIndex })));

// Project dashboard routes lazy loading
const ProjectDashboard = lazy(() => import('../routes/projects/projectDashboard'));
const ProjectDashboardEdit = lazy(() =>
  import('../routes').then((module) => ({ default: module.ProjectDashboardEdit })),
);
const ProjectDashboardSubscribers = lazy(() => import('../routes/projects/projectSubscribers'));
const ProjectDashboardContributions = lazy(() => import('../routes/projects/projectContributions'));
const ProjectDashboardPackCreate = lazy(() => import('../routes/projects/projectPackCreate'));
const UserProjectsIndex = lazy(() => import('../routes/users/userProjects'));

// Payment routes lazy loading
const PaymentIndex = lazy(() => import('../routes/payments/payment'));
const AfterPaymentIndex = lazy(() => import('../routes/payments/afterPayment'));
const CartIndex = lazy(() => import('../routes/users/cart'));

// Auth components
const PasswordReset = lazy(() => import('./auth/PasswordReset'));
const CancelPasswordReset = lazy(() =>
  import('./auth/CancelPasswordReset').then((module) => ({ default: module.CancelPasswordReset })),
);
const LoginLinkedIn = lazy(() => import('./auth/LoginLinkedIn'));

// User components
const ProfileIndex = lazy(() => import('../routes').then((module) => ({ default: module.ProfileIndex })));
const ProjectsUser = lazy(() => import('./user/ProjectsUser'));

// Widget
const ProjectWidgetRoute = lazy(() => import('../routes/projects/ProjectWidgetRoute'));

function App() {
  const params = useParams();
  let location = useLocation();

  useEffect(() => {
    SegmentService.load();
  }, []);

  useEffect(() => {
    SegmentService.page();
  }, [location]);

  const items = (params = null) => [
    {
      name: 'our_projects',
      to: !params.brand ? '/search' : `/${params.brand}`,
    },
    {
      name: 'capital_investment',
      to: 'https://www.mymoojo.com/investir-en-capital/',
    },
  ];

  const adminItems = (params = null) => {
    const root = !params.brand ? '' : `/${params.brand}`;
    return [
      {
        name: 'projects',
        to: `${root}/admin/projects`,
      },
      {
        name: 'users',
        to: `${root}/admin/users`,
      },
      {
        name: 'categories',
        to: `${root}/admin/categories`,
      },
      {
        name: 'parameters',
        to: `${root}/admin/parameters`,
      },
      {
        forBrand: false,
        name: 'brands',
        to: `${root}/admin/brands`,
      },
      {
        name: 'presentation_fields',
        to: `${root}/admin/presentation_fields`,
      },
      {
        forBrand: false,
        name: 'featured_items',
        to: `${root}/admin/featured_items`,
      },
      {
        forBrand: false,
        name: 'featured_users',
        to: `${root}/admin/featured_users`,
      },
      {
        forBrand: false,
        name: 'tasks',
        to: `${root}/admin/tasks`,
      },
      {
        name: 'sdgs',
        to: `${root}/admin/sdgs`,
      },
      {
        name: 'partnerships',
        to: `${root}/admin/partnerships`,
      },
    ];
  };

  const caching = () => {
    const version = localStorage.getItem('version');

    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  caching();

  return (
    <Suspense fallback={<Loading />}>
      <SentryRoutes>
        {/* Basic MyMoojo website */}
        <Route path='' element={<ThemeDataProvider />}>
          {/* Redirect to search page if homepage */}
          <Route index element={<Navigate to='/search' />} />

          {/* Dashboard routes */}
          <Route path='dashboard' element={<ProtectedLayout />}>
            <Route
              index
              element={
                <ProtectedRoute
                  path='/dashboard/account'
                  element={<DashboardIndex items={items(params)} active={null} />}
                />
              }
            />
            <Route
              path='account'
              element={<ProtectedRoute path='/dashboard/account' element={<UserAccountIndex />} />}
            />
            <Route
              path='contributions'
              element={<ProtectedRoute path='/dashboard/contributions' element={<ContributionsIndex />} />}
            ></Route>
            <Route path='projects'>
              <Route index element={<ProtectedRoute path='/dashboard/projects' element={<ProjectsUser />} />} />
              <Route
                path=':id'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id'
                    element={<ProjectDashboard items={items} active={null} />}
                  />
                }
              />
              {/* TODO: Removed 31/01/2025 because now we are handling forfait subscription during the first call */}
              {/*<Route*/}
              {/*  path=':id/forfait'*/}
              {/*  element={*/}
              {/*    <ProtectedRoute*/}
              {/*      path='/dashboard/projects/:id/forfait'*/}
              {/*      element={<ProjectForfait items={items} active={null} />}*/}
              {/*    />*/}
              {/*  }*/}
              {/*/>*/}
              <Route
                path=':id/edit'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id/edit'
                    element={<ProjectDashboardEdit items={items} active={null} />}
                  />
                }
              />
              <Route
                path=':id/subscribers'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id/subscribers'
                    element={<ProjectDashboardSubscribers items={items} active={null} />}
                  />
                }
              />
              <Route
                path=':id/contributions'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id/contributions'
                    element={<ProjectDashboardContributions items={items} active={null} />}
                  />
                }
              />
              <Route
                path=':id/contribution'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id/contribution'
                    element={<ProjectDashboardPackCreate title='add_a_contribution' />}
                  />
                }
              />
              <Route
                path=':id/contribution/:packId'
                element={
                  <ProtectedRoute
                    path='/dashboard/projects/:id/contribution/:packId'
                    element={<ProjectDashboardPackCreate title='edit_a_contribution' />}
                  />
                }
              />
            </Route>
          </Route>

          {/* Old routes to redirect */}
          <Route
            path='/dashboard/*'
            element={<Navigate replace to={window.location.pathname.replace('/dashboard', '')} />}
          />

          {/* Basic routes */}
          <Route path='search' element={<ListOfProjects items={items} active='/search' />} />

          {/* Footer routes */}
          <Route path='legal' element={<Legal items={items} active={null} />} />
          <Route path='faq' element={<Faq items={items} active={null} />} />
          <Route path='support' element={<Support items={items} active={null} />} />

          {/* Project routes */}
          <Route path='project/:slug' element={<ProjectsIndex items={items} active={null} />} />

          {/* Widgets routes */}
          <Route path='widget/project/:slug' element={<ProjectWidgetRoute items={items} active={null} />} />

          {/* Payment routes */}
          <Route
            path='cart'
            element={<ProtectedRoute path='/cart' element={<CartIndex items={items} active={null} />} />}
          />
          <Route path='payment' element={<PaymentIndex items={items} active={null} />} active={null} />
          <Route
            path='after_payment'
            element={
              <ProtectedRoute path='/after_payment' element={<AfterPaymentIndex items={items} active={null} />} />
            }
            active={null}
          />

          {/* Auth routes */}
          <Route path='login' element={<LoginIndex items={items} active={null} />} />
          <Route path='signup' element={<SignupIndex items={items} active={null} />} />
          <Route path='passwordReset'>
            <Route index element={<PasswordResetIndex items={items} active={null} />} />
            <Route path=':id/:token' element={<PasswordReset items={null} active={null} />} />
            <Route path='cancel/:id/:token' element={<CancelPasswordReset />} />
          </Route>
          <Route path='auth/linkedin' element={<LoginLinkedIn />} />

          {/* User routes */}
          <Route path='user'>
            <Route path=':id' element={<ProfileIndex items={items} active={null} />} />
          </Route>

          {/* Administration routes */}
          <Route path='admin'>
            <Route
              index
              element={
                <ProtectedRoute
                  path='/admin'
                  element={<AdminDashboardIndex adminItems={adminItems} items={items} active='/admin' />}
                />
              }
            />
            <Route
              path='projects'
              element={
                <ProtectedRoute
                  path='/admin/projects'
                  element={<AdminProjectsIndex adminItems={adminItems} items={items} active='/admin/projects' />}
                />
              }
            />
            <Route
              path='tasks'
              element={
                <ProtectedRoute
                  path='/admin/tasks'
                  element={<AdminTasksIndex adminItems={adminItems} items={items} active='/admin/tasks' />}
                />
              }
            />
            <Route
              path='sdgs'
              element={
                <ProtectedRoute
                  path='/admin/sdgs'
                  element={<AdminSDGsIndex adminItems={adminItems} items={items} active='/admin/sdgs' />}
                />
              }
            />
            <Route
              path='partnerships'
              element={
                <ProtectedRoute
                  path='/admin/partnerships'
                  element={
                    <AdminPartnershipsIndex adminItems={adminItems} items={items} active='/admin/partnerships' />
                  }
                />
              }
            />
            <Route
              path='categories'
              element={
                <ProtectedRoute
                  path='/admin/categories'
                  element={<AdminCategoriesIndex adminItems={adminItems} items={items} active='/admin/categories' />}
                />
              }
            />
            <Route
              path='users'
              element={
                <ProtectedRoute
                  path='/admin/users'
                  element={<AdminUsersIndex adminItems={adminItems} items={items} active='/admin/users' />}
                />
              }
            />
            <Route
              path='parameters'
              element={
                <ProtectedRoute
                  path='/admin/parameters'
                  element={<AdminParametersIndex adminItems={adminItems} items={items} active='/admin/parameters' />}
                />
              }
            />
            <Route
              path='brands'
              element={
                <ProtectedRoute
                  path='/admin/brands'
                  element={<AdminBrandsIndex adminItems={adminItems} items={items} active='/admin/brands' />}
                />
              }
            />
            <Route
              path='presentation_fields'
              element={
                <ProtectedRoute
                  path='/admin/presentation_fields'
                  element={
                    <AdminPresentationFieldsIndex
                      adminItems={adminItems}
                      items={items}
                      active='/admin/presentation_fields'
                    />
                  }
                />
              }
            />
            <Route
              path='featured_items'
              element={
                <ProtectedRoute
                  path='/admin/featured_items'
                  element={
                    <AdminFeaturedItemsIndex adminItems={adminItems} items={items} active='/admin/featured_items' />
                  }
                />
              }
            />
            <Route
              path='featured_users'
              element={
                <ProtectedRoute
                  path='/admin/featured_users'
                  element={
                    <AdminFeaturedUsersIndex adminItems={adminItems} items={items} active='/admin/featured_users' />
                  }
                />
              }
            />
          </Route>
        </Route>

        {/* Branded website */}
        <Route exact path='/:brand' element={<BrandChecker />}>
          <Route index element={<App items={items} active='/:brand' />} />
          <Route path='search' element={<ListOfProjects items={items} active='/:brand/search' />} />
          {/* Project routes */}
          <Route path='project'>
            <Route index element={<UserProjectsIndex items={items} active={null} />} />
            <Route path='edit/:id' element={<ProjectDashboardEdit items={items} active={null} />} />
            <Route path=':id' element={<ProjectsIndex items={items} active={null} />} />
          </Route>

          <Route path='user'>
            <Route path='cart' element={<CartIndex items={items} active={null} />} />
          </Route>

          {/* Payment routes */}
          <Route path='payment' element={<PaymentIndex items={items} />} active={null} />
          <Route
            path='after_payment'
            element={<ProtectedRoute path='/after_payment' element={<AfterPaymentIndex items={items} />} />}
            active={null}
          />

          {/* Administration routes */}
          <Route path='admin'>
            <Route
              index
              element={<AdminDashboardIndex adminItems={adminItems} items={items} active='/:brand/admin' />}
            />
            <Route
              path='projects'
              element={<AdminProjectsIndex adminItems={adminItems} items={items} active='/:brand/admin/projects' />}
            />
            <Route
              path='categories'
              element={<AdminCategoriesIndex adminItems={adminItems} items={items} active='/:brand/admin/categories' />}
            />
            <Route
              path='users'
              element={<AdminUsersIndex adminItems={adminItems} items={items} active='/:brand/admin/users' />}
            />
            <Route
              path='presentation_fields'
              element={
                <AdminPresentationFieldsIndex
                  adminItems={adminItems}
                  items={items}
                  active='/:brand/admin/presentation_fields'
                />
              }
            />
          </Route>
        </Route>

        {/* 404 NOT FOUND */}
        <Route path='*' element={<Navigate to='/?notFound' />} />
        {/*</Routes>*/}
      </SentryRoutes>
    </Suspense>
  );
}

export default App;
