import React, { createContext, useCallback, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styleSheet } from './styleSheet';
import CssBaseline from '@mui/material/CssBaseline';

export const theme = (args) => {
  const defaultArgs = {
    logo: `${process.env.PUBLIC_URL}/images/projects/logo_mymoojo.png`,
    primaryColor: '#FFDB44',
    secondaryColor: '#05A488',
    tertiaryColor: '#e05d9c',
    primaryDark: '#ffcf33',
  };

  return createTheme({
    palette: {
      primary: {
        main: args.primaryColor ?? defaultArgs.primaryColor,
        dark: args.primaryDark ?? defaultArgs.primaryDark,
      },
      secondary: {
        main: args.secondaryColor ?? defaultArgs.secondaryColor,
      },
      tertiary: {
        main: args.tertiaryColor ?? defaultArgs.tertiaryColor,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    images: {
      logo: args.logo ?? defaultArgs.logo,
    },
    typography: {
      fontFamily: ['Work', 'Roboto', 'sans-serif'].join(','),
    },
    noConnection: args.noConnection ?? false,
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  });
};

export const classes = (withTheme) => styleSheet(withTheme);

export const themeContext = createContext({ theme: theme({}), brandId: null });

export function ThemeProvider({ children }) {
  const [data, setData] = useState({ theme: theme({}), brandId: null });

  const setTheme = useCallback((args = {}, brandId) => {
    const tmpTheme = theme(args);
    setData({ theme: tmpTheme, brandId });
  }, []);

  const { Provider } = themeContext;
  return (
    <Provider value={{ theme: data.theme, brandId: data.brandId, setTheme }}>
      <MuiThemeProvider theme={data.theme}>
        <CssBaseline />
        <GlobalStyles styles={classes(data.theme)} />
        {children}
      </MuiThemeProvider>
    </Provider>
  );
}
